import classnames from "classnames"
import { graphql } from "gatsby"
import React from "react"
import { IHomepage } from "../../@types/types"
import Layout from "../components/Layout"
import PageSection from "../components/PageSection"
import Seo from "../components/Seo"

const Index = ({
  data,
}: {
  data: { contentfulHomepage: IHomepage }
}): JSX.Element => {
  const {
    contentfulHomepage: { title, description, sections, headerLinks },
  } = data

  const links = [
    { id: "1", title: "Pricing", href: "#Pricing" },
    { id: "2", title: "Get Started", href: "#Get-Started" },
    { id: "3", title: "News", href: "/articles" },
  ]

  const headerClassName = classnames({
    "lg:absolute lg:z-20 lg:inset-x-0":
      sections[0].content.__typename === "ContentfulHeroSection",
  })

  return (
    <Layout headerLinks={links} headerClassName={headerClassName}>
      {({ handleAction }) => (
        <>
          <Seo title={title} description={description} />

          {sections.map(({ id, ...props }, index) => (
            <PageSection
              key={id}
              id={id}
              {...props}
              index={index}
              onAction={handleAction}
            />
          ))}
        </>
      )}
    </Layout>
  )
}

export default Index

export const query = graphql`
  query IndexQuery {
    contentfulHomepage {
      title
      description
      headerLinks {
        id
        title
      }
      sections {
        id
        title
        content {
          __typename
          ... on ContentfulHeroSection {
            id
            orientation
            heading1
            heading2
            introduction {
              childMarkdownRemark {
                html
              }
            }
            heroImage {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            callsToAction {
              id
              text
              url
              action {
                event
                arguments
              }
              background
            }
          }
          ... on ContentfulFeatureSection {
            title
            heading
            description {
              description
            }
            features {
              id
              title
              icon
              description {
                description
              }
            }
          }
          ... on ContentfulTestimonialSection {
            testimonial {
              id
              cite
              quote {
                quote
              }
              image {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          ... on ContentfulMarkdownSection {
            id
            content {
              childMarkdownRemark {
                html
              }
            }
            callsToAction {
              id
              text
              url
              action {
                event
                arguments
              }
              background
            }
          }
          ... on ContentfulCtaSection {
            id
            callsToAction {
              id
              text
              url
              action {
                event
                arguments
              }
              background
            }
          }
        }
      }
    }
  }
`
